import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ExpiringCreditCard } from 'icons/ExpiringCreditCard/ExpiringCreditCard'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { Button, ButtonType } from '@junglescout/edna'
import { Modal } from '../common/junglekit/ui_elements/Modal/Modal'

const StyledModal = styled(Modal)`
  width: 520px;
  height: 396px;

  & > [class*='Content'] {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  ${TEXT_STYLES.H4White}
`

const Header = styled.div`
  ${TEXT_STYLES.Coaching1}
  margin: 20px 5px 11px 5px;
`

const Body = styled.div`
  ${TEXT_STYLES.Coaching2Grey}
  line-height: 1.5 !important;
  font-weight: normal !important;
  margin: 0 5px 25px 5px;
`

const isCreditCardExpired = cardInfo => {
  const today = moment(Date.now())
  const expirationDate = cardInfo
    ? moment(new Date(cardInfo.exp_year, cardInfo.exp_month - 1)).endOf('month')
    : null
  return today.isAfter(expirationDate)
}

const ExpiringCreditCardModal = ({ history, setGlobalModal, cardInfo }) => {
  const { t } = useTranslation(['account'])
  const close = () => setGlobalModal(null)
  const expired = isCreditCardExpired(cardInfo)
  return (
    <StyledModal
      isVisible
      handleCloseButtonClick={close}
      handleBackdropClick={close}>
      <ExpiringCreditCard
        title={expired ? 'Credit card expired' : 'Credit card expiring'}
      />

      {expired ? (
        <Header>
          {t(
            'account:ExpiredCreditCardModal.title',
            'It looks like your credit card has expired.'
          )}
        </Header>
      ) : (
        <Header>
          {t(
            'account:ExpiringCreditCardModal.title',
            'It looks like your credit card is about to expire.'
          )}
        </Header>
      )}
      {expired ? (
        <Body>
          {t(
            'account:ExpiredCreditCardModal.body',
            'Update your payment information to prevent your Jungle Scout plan from cancelling.'
          )}
        </Body>
      ) : (
        <Body>
          {t(
            'account:ExpiringCreditCardModal.body',
            'Update your payment information now so that there is no suspension of your Jungle Scout account.'
          )}
        </Body>
      )}

      <StyledButton
        onClick={() => {
          close()
          history.push('/account/billing')
        }}
        btnType={ButtonType.PRIMARY}>
        {t(
          'account:ExpiringCreditCardModal.button',
          'Update Payment Information'
        )}
      </StyledButton>
    </StyledModal>
  )
}

ExpiringCreditCardModal.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setGlobalModal: PropTypes.func.isRequired,
  cardInfo: PropTypes.objectOf(PropTypes.any).isRequired
}

export default ExpiringCreditCardModal
