import React from 'react'
import styled from 'styled-components'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { useTranslation } from 'react-i18next'
import { JSPRO_BUCKET } from 'constants/s3'

const ContentWrapper = styled.div`
  width: 515px;
`

const ModalHeader = styled.h3`
  ${TEXT_STYLES.Coaching1}
  line-height: normal;
  max-width: 300px;
  margin: 0 auto;
`
const ModalBody = styled.p`
  ${TEXT_STYLES.Coaching2Bold}
  margin-bottom: 42px;
  margin-top: 14px;
`
const ImageWrapper = styled.img`
  display: block;
  margin: 30px auto 34px;
  width: 348px;
  height: 160px;
`

const UnlockSellerFeaturesModal = () => {
  const { t } = useTranslation('mwsSync')

  return (
    <ContentWrapper>
      <ImageWrapper
        src={`${JSPRO_BUCKET}/images/upsell/feature-limits.svg`}
        alt="Unlock Seller Features Modal image"
      />
      <ModalHeader>
        {t(
          'mwsSync:UnlockSellerFeaturesModal.header',
          'Want to unlock Seller Features?'
        )}
      </ModalHeader>
      <ModalBody>
        {t(
          'mwsSync:UnlockSellerFeaturesModal.body',
          'Contact your account owner to connect to Amazon.'
        )}
      </ModalBody>
    </ContentWrapper>
  )
}
export { UnlockSellerFeaturesModal }
